import { Flex } from '@pancakeswap/uikit'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { SpinnerProps } from './types'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  position: relative;
`

const RotatingPancakeIcon = styled.img`
    background: url('/images/seal.png')
  position: absolute;
  top: 0;
  left: 0;
  animation: ${rotate} 2s linear infinite;
  transform: translate3d(0, 0, 0);
`

export const SpinnerLayout = styled(Flex)`
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Spinner: React.FC<SpinnerProps> = ({ size = 128 }) => {
  return (
    <Container>
      <RotatingPancakeIcon width={`${size * 0.8}px`} src="/images/logo.png" />
    </Container>
  )
}

export default Spinner
